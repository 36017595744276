import router from "./index"
const whiteList = ['/science_child1', '/', '/culture' ,'/construction', '/science_child7'] // no redirect whitelist

router.beforeEach((to, from, next) => {
    console.log('路由跳转之前', to)
    if (localStorage.getItem('phone')) {
        console.log(123);
        next()
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            console.log(456);
            // 在免登录白名单，直接进入
            console.log(whiteList.indexOf(to.path) !== -1);
            next()
        } else {
            console.log(789);
            next('/science_child1') // 否则全部重定向到登录页
        }
    }
})