<template>
  <div id="app" ref="main">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  mounted() {
    var html = document.documentElement;
    var setFontSize = function() {
        var width = html.offsetWidth;
        if(width <= 750) {
            html.style.fontSize = width / 7.5 + 'px';
        } else {
            html.style.fontSize = 100 + 'px';
        }
    };
    var timer;
    var setDelay = function() {
        return clearTimeout(timer), (timer = setTimeout(setFontSize, 150));
    }
    window.addEventListener('pageshow', function(evt) {
        return evt.persisted && setDelay();
    });
    window.addEventListener('resize', setDelay);
    setFontSize();
  },
  watch: {
    $route() {
      var that = this;
      let main = this.$refs.main;
      document.body.scrollTo(0, 0);
    },
  },
  methods: {},
};
</script>
<style>
  body{
    /* filter: grayscale(1); */
  }

</style>
